import type { ElmProgram, ElmPorts } from "./ElmMount";

export function init(elmProgram: ElmProgram<Ports>) {
  elmProgram.ports.analyticsTrack.subscribe(({ name, data }) => {
    if (process.env.NODE_ENV === "development") {
      console.groupCollapsed(`Logging Analytics from Elm: ${name}`);
      console.log("Event data: ", data);
      console.groupEnd();
    }
    try {
      window.amplitudeTeacherInstance().logEvent(name, data);
    } catch (e) {
      console.log("Error logging event: ", e);
    }
  });
}

type Ports = ElmPorts<{
  subscribe: {
    analyticsTrack: { name: string; data: unknown };
  };
  send: {};
}>;

// TODO: Migrate existing amplitude code to TypeScript and import it
// here instead.
declare global {
  interface Window {
    amplitudeTeacherInstance(): {
      logEvent(name: string, data: unknown): void;
    };
  }
}
