import { ElmPorts } from "lib/ElmMount";

export default function setupAuthenticationPorts(ports: Ports) {
  let reauthWindow: Window | null;

  ports.openReauthenticationWindow.subscribe(([component, url]) => {
    if (reauthWindow) {
      reauthWindow.close();
    }

    reauthWindow = window.open(url, "oauth_popup", "left=300,top=300,width=600,height=600");
    if (!reauthWindow) return;

    const interval: any = setInterval(() => {
      if (!reauthWindow) return clearInterval(interval); // in case it got closed already
      reauthWindow.postMessage("oauth_ping", window.location.origin);
    }, 100);

    window.addEventListener(
      "message",
      (event) => {
        if (event.origin !== window.location.origin) return;
        clearInterval(interval);
        try {
          if (typeof event.data !== "string") {
            return;
          }

          const eventData = JSON.parse(event.data);
          if (eventData["authenticationResult"] === "success") {
            if (reauthWindow) {
              reauthWindow.close();
            }

            ports.ssoUserReauthenticated.send([component, eventData["redirectLocation"]]);
          } else if (eventData["authenticationResult"] === "failure") {
            if (reauthWindow) {
              reauthWindow.close();
            }
            ports.ssoUserAuthenticationFailed.send(eventData["errorMessage"]);
          }
        } catch (e) {
          if (e instanceof SyntaxError) {
            throw e;
          }
        }
      },
      false
    );
  });
}

type Ports = ElmPorts<{
  subscribe: {
    openReauthenticationWindow: string[];
  };
  send: {
    ssoUserReauthenticated: [string, string];
    ssoUserAuthenticationFailed: string;
  };
}>;
