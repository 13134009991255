import * as path from "path";

/**
 * Used to initialize your Elm program, in conjunction with the `elm_mount` view helper.
 *
 * First, in your Ruby code:
 *
 * ```
 * <%= elm_mount "my/typescript/entry_point", flags: {foo: "bar"} %>
 * ```
 *
 * And then in your entry point:
 *
 * ```
 * import {Elm} from "elm/My/Elm/Module/Main";
 * import ElmMount from "lib/ElmMount";
 *
 * const elmProgram = new ElmMount("my/typescript/entry_point").init(Elm.My.Elm.Module.Main);
 *
 * elmProgram.ports.myPort.subscribe(() => { ... });
 * ```
 *
 * When doing interop with Typescript, you should prefer using Elm ports when possible.
 * However, if you need access to data from your controller in the entrypoint before you
 * start the Elm program, you can use the `pageFlags` property:
 *
 * ```
 * interface PageFlags {
 *   key: string;
 * }
 *
 * const elmMount = newElmMount<PageFlags>("my/typescript/entry_point");
 * const {pageFlags} = elmMount;
 *
 * someThirdPartyJsStuff(pageflags.key)
 * ```
 */
export default class ElmMount<Flags> {
  private _pageFlags?: Flags;
  private _node?: HTMLElement;

  constructor(private _modulePath: string) {}

  init<Ports>(elmModule: ElmModule<Flags, Ports>): ElmProgram<Ports> {
    if (process.env.NODE_ENV === "development") {
      console.groupCollapsed("Mounting Elm Program");
      if (this.node) {
        console.log("node: ", this.node.id);
      } else {
        console.log("no node parsed");
      }
      if (this.pageFlags) {
        console.log("flags: ", this.pageFlags);
      } else {
        console.log("no flags parsed");
      }
      console.groupEnd();
    }

    try {
      return elmModule.init({
        node: this.node,
        flags: this.pageFlags,
      });
    } catch (e) {
      if (e.name !== "Elm Mount Error") {
        throw createMountError(e.message);
      } else {
        throw e;
      }
    }
  }

  get pageFlags(): Readonly<Flags> {
    if (this._pageFlags) {
      return this._pageFlags;
    }

    const pageFlags = this.node.dataset.esPageFlags;

    if (!pageFlags) {
      throw createMountError(
        "Failed to parse Elm page flags. Make sure to use `elm_mount` in your view."
      );
    }

    // TODO: This is an unsafe cast. How do we know that the server provided the flags
    // that we expect? We don't. We may want to look into a decoding library like
    // https://github.com/gcanti/io-ts if we still want to to access page flags
    // on the Typescript side.
    this._pageFlags = JSON.parse(pageFlags) as Flags;
    return this._pageFlags;
  }

  private get node() {
    if (this._node) {
      return this._node;
    }

    const mountPointId = mountPointIdFromModulePath(this._modulePath);
    const mountNode = document.getElementById(mountPointId);

    if (!mountNode) {
      throw createMountError(
        `Failed to find elm mount point "${mountPointId}". Make sure to use \`elm_mount\` in your view.`
      );
    }

    this._node = mountNode;
    return this._node;
  }
}

export interface ElmModule<Flags, Ports> {
  init(options: { node?: HTMLElement | null; flags: Flags }): ElmProgram<Ports>;
}

export interface ElmProgram<Ports> {
  ports: Ports;
}

export type ElmPorts<P extends { subscribe: {}; send: {} }> = {
  [T in keyof P["subscribe"]]: {
    subscribe: (callback: (data: P["subscribe"][T]) => void) => void;
  };
} & {
  [T in keyof P["send"]]: {
    send: (data: P["send"][T]) => void;
  };
};

/**
 * The type definitions generated by`elm-typescript-interop` do not include
 * the `unsubscribe` that exists on subscribtion ports. Use this type to
 * assert they exist in order to call them
 */
export type UnsubscribablePorts<P extends { subscribe: {}; send: {} }> = ElmPorts<P> & {
  [T in keyof P["subscribe"]]: {
    unsubscribe: (callback: (data: P["subscribe"][T]) => void) => void;
  };
};

// Transforms module path to the id of the mount node, generated by the Rails elm_mount helper.
// Example:
// `app/javascript/packs/foo/bar.ts`
// is converted to
// `elm-mount-point-foo-bar`
function mountPointIdFromModulePath(modulePath: string): string {
  const moduleSuffix = modulePath
    .replace("app/javascript/packs/", "")
    .replace(path.extname(modulePath), "")
    .replace(/\//g, "-")
    .toLowerCase();
  return `elm-mount-point-${moduleSuffix}`;
}

function createMountError(message: string): Error {
  const error = new Error(message);
  error.name = "Elm Mount Error";
  return error;
}
